<template>
  <div>
    <v-card>
      <v-card-title>
        <span>ユーザー 一覧</span>
        <v-spacer></v-spacer>
        <v-btn router to="/main/user/create" height="35px" width="35px" fab>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <Loading v-if="isLoading" />
      <p v-else-if="showUsers === null">通信エラー</p>
      <v-card-text class="pa-0" v-else>
        <div class="pt-4 px-4 pb-2">
          <v-form class="d-flex">
            <li class="mr-3">
              <v-text-field
                dense
                label="ユーザー名"
                placeholder="ユーザー名を入力"
                prepend-inner-icon="mdi-magnify"
                outlined
                color="primary"
                :value="keyword"
                @input="onSearch"
              ></v-text-field>
            </li>
            <li class="mr-3" style="width: 250px">
              <v-select
                :items="formTeams"
                @change="onTeam"
                :value="0"
                label="チーム"
                item-value="value"
                item-text="label"
                prepend-inner-icon="mdi-account-supervisor-circle"
                outlined
                dense
                color="primary"
              >
              </v-select>
            </li>
            <li class="mr-3" style="width: 250px">
              <v-select
                :items="formAttributes"
                @change="onAttribute"
                :value="0"
                label="属性"
                item-value="value"
                item-text="label"
                prepend-inner-icon="mdi-badge-account-horizontal"
                outlined
                dense
                color="primary"
              >
              </v-select>
            </li>
          </v-form>
          <div class="d-flex align-end">
            <p v-if="showUsers.length" style="width: 250px">
              全 {{ afterFilter.length }} 件中 {{ pageFirstUser }} 件 〜
              {{ pageLastUser }} 件を表示
            </p>
            <p v-else>全 0 件</p>
            <div style="width: calc(100% - 250px); padding: 0 100px 0 150px">
              <v-pagination
                @input="onClickPaginate"
                :value="currentPage"
                :length="maxPage"
                color="primary"
                dense
              ></v-pagination>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <v-simple-table>
          <thead>
            <tr>
              <th>チーム</th>
              <th>属性</th>
              <th>名前</th>
              <th>メール</th>
            </tr>
          </thead>
          <p v-if="!showUsers.length" class="text-center">データはありません</p>
          <tbody>
            <tr
              v-for="(user, index) in showUsers"
              :key="index"
              @click="$router.push(`/main/user/read?userId=${user.id}`)"
              v-ripple
            >
              <td>
                {{
                  TEAM.find(
                    (team) => team.value == user.team_id
                  )?.label
                }}
              </td>
              <td>
                {{
                  ATTRIBUTE.find(
                    (attribute) => attribute.value == user.attribute
                  )?.label
                }}
              </td>
              <td>
                <v-avatar size="30px" class="mr-1">
                  <v-img
                    v-if="!user.img"
                    :src="require('@/assets/images/user.png')"
                  ></v-img>
                  <v-img
                    v-else-if="$store.state.picture"
                    :src="
                      VUE_APP_BACK_URL +
                      '/storage/images/' +
                      $store.state.picture
                    "
                  ></v-img>
                  <v-img
                    v-else
                    :src="VUE_APP_BACK_URL + '/storage/images/' + user.img"
                  ></v-img>
                </v-avatar>
                <span>{{ user.name }}</span>
              </td>
              <td>{{ user.email }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { TEAM } from "@/plugins/const";
import { ATTRIBUTE } from "@/plugins/const";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      TEAM: TEAM,
      ATTRIBUTE: ATTRIBUTE,
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      keyword: "",
      attribute: "",
      team_id: "",
      VUE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
    };
  },
  computed: {
    usersNum() {
      return this.afterFilter.length;
    },
    maxPage() {
      return Math.ceil(this.usersNum / this.perPage);
    },
    afterFilter() {
      if (!this.$store.state.users) return [];
      if (!this.attribute && !this.team_id) {
        return this.$store.state.users.filter(
          (a) => a.name.indexOf(this.keyword) !== -1
        );
      } else if(this.attribute && !this.team_id) {
        return this.$store.state.users.filter(
          (a) =>
            a.name.indexOf(this.keyword) !== -1 && a.attribute == this.attribute
        );
      } else if(!this.attribute && this.team_id) {
        return this.$store.state.users.filter(
          (a) =>
            a.name.indexOf(this.keyword) !== -1 && a.team_id == this.team_id
        );
      }else{
        return this.$store.state.users.filter(
          (a) =>
            a.name.indexOf(this.keyword) !== -1 && a.team_id == this.team_id  && a.attribute == this.attribute
        );
      }
    },
    showUsers() {
      return this.afterFilter.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    pageFirstUser() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    perPageLastUser() {
      return this.currentPage * this.perPage;
    },
    pageLastUser() {
      if (this.perPageLastUser <= this.afterFilter.length) {
        return this.perPageLastUser;
      } else {
        return this.afterFilter.length;
      }
    },
    formAttributes() {
      return [
        {
          value: 0,
          label: "全て",
        },
      ].concat(ATTRIBUTE);
    },
    formTeams() {
      return [
        {
          value: 0,
          label: "全て",
        },
      ].concat(TEAM);
    },
  },
  methods: {
    onSearch(userSearch) {
      this.keyword = userSearch;
      this.currentPage = 1;
    },
    onTeam(userSearch) {
      this.team_id = userSearch;
      this.currentPage = 1;
    },
    onAttribute(userSearch) {
      this.attribute = userSearch;
      this.currentPage = 1;
    },
    onClickPaginate(page) {
      this.currentPage = page;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("getUsers");
    this.isLoading = false;
  },
};
</script>

<style>
.mdi-account-supervisor-circle::before {
    font-size: 28px;
}
</style>